'use client';
import { mixpanelPopupView } from '@/lib/mixpanel/services/client';
import blocksToText from '@/sanity/utils/shopify/blocksToText';
import { userClickSSULinkAtom } from '@/store/ssuStore';
import {
  toastsShownPerSessionAtom,
  toastAddToCartAtom,
} from '@/store/toastsStore';
import { useAtom, useAtomValue } from 'jotai';
import React, { useEffect, useRef } from 'react';
import { toast as toastify, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastContent from './ToastContent';
import { Toast } from '@/types/types';
import { usePathname } from 'next/navigation';

/**
 * Toast Component from Sanity notifcations
 * @returns JSX Element for the toast component
 */
export default function ToastTrigger({ toast }: { toast: Toast }) {
  const userClickSSULink = useAtomValue(userClickSSULinkAtom);
  const userAddedToCart = useAtomValue(toastAddToCartAtom);
  const [toastsShownPerSession, setToastsShownPerSession] = useAtom(
    toastsShownPerSessionAtom
  );

  const pathname = usePathname();
  const showToast = useRef(false);
  const timeoutId = useRef<NodeJS.Timeout | null>(null);
  const intervalId = useRef<NodeJS.Timeout | null>(null);

  // Cleanup function to clear timeouts and intervals
  const clearTimers = () => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
      timeoutId.current = null;
    }
    if (intervalId.current) {
      clearInterval(intervalId.current);
      intervalId.current = null;
    }
  };

  const delay = toast?.displayOptions?.delay
    ? toast.displayOptions.delay * 1000
    : 5000;

  const duration = toast?.displayOptions?.duration
    ? toast?.displayOptions?.duration * 1000
    : 10000;

  // Function to check if the pathname should be excluded
  const isPathExcluded = (pathname: string, excludePatterns: string[]) => {
    return excludePatterns.some((pattern) => {
      if (pattern.endsWith('*')) {
        // Correctly remove '*' to get the base path for pattern match
        const basePath = pattern.slice(0, -1);
        return pathname.startsWith(basePath);
      }
      return pathname === pattern;
    });
  };

  // Function to update the toastsShownPerSession atom added to session list
  const updateToastsShownPerSession = () => {
    setToastsShownPerSession((prev) => {
      // Ensure prev is an array, handling null or undefined gracefully
      const safePrev = Array.isArray(prev) ? prev : [];
      // Check if toast._key is not already included before adding
      if (!safePrev.includes(toast._key)) {
        return [toast._key, ...safePrev];
      }
      // Return the previous state if toast._key is already included
      return safePrev;
    });
  };

  useEffect(() => {
    // Immediately clear timers if conditions are met
    const isUserClickSSULink =
      userClickSSULink && toast?.button?.link?.linkType === 'ssu';
    const isToastShown =
      toast?._key && toastsShownPerSession?.includes(toast?._key);
    const isUserAddedToCartNull =
      userAddedToCart === null &&
      toast?.button?.link?.url === 'purchase-journey';

    if (isUserClickSSULink || isToastShown || isUserAddedToCartNull) {
      clearTimers();
      return;
    }

    if (!showToast.current) {
      showToast.current = true;
      timeoutId.current = setTimeout(() => {
        const excludePaths = toast.displayOptions?.exclude?.routes || [];

        intervalId.current = setInterval(() => {
          if (toast.displayOptions?.exclude?.routes.length > 0) {
            if (isPathExcluded(window.location.pathname, excludePaths)) {
              return;
            }
          }

          toastify.info(ToastContent({ toast }), {
            onClick: updateToastsShownPerSession,
            onClose: updateToastsShownPerSession,
            onOpen: () => {
              // @ts-ignore
              mixpanelPopupView(blocksToText(toast?.message));
            },
          });

          clearTimers(); // Clear interval after showing toast
        }, 1000);
      }, delay);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userClickSSULink,
    toastsShownPerSession,
    pathname,
    userAddedToCart,
    toast,
  ]);

  // Component will render null if conditions are met
  const isUserClickSSULink =
    userClickSSULink && toast?.button?.link?.linkType === 'ssu';
  const isToastShown =
    toast?._key && toastsShownPerSession?.includes(toast?._key);
  const isUserAddedToCartNull =
    userAddedToCart === null && toast?.button?.link?.url === 'purchase-journey';

  if (isUserClickSSULink || isToastShown || isUserAddedToCartNull) {
    return null;
  }

  return (
    <ToastContainer
      position={toast?.displayOptions?.position}
      autoClose={duration}
      draggable
      theme='light'
      icon={false}
      className={`!z-400 md:!w-[400px]`}
      toastClassName={`!bg-gradient-to-r !bg-gradient-teal !text-white !shadow !shadow-lg`}
      progressClassName={`!bg-charcoal`}
    />
  );
}
